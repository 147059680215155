<template>
  <div>

    <loading :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>

    <form method="post" @submit.prevent="onSubmit('frmTeam')" data-vv-scope="frmTeam" novalidate="novalidate">
      <div class="row justify-content-md-center pt-2 px-2">

        <!-- Card Esquerda -->
        <div class="col-md-6 px-2">
          <div class="card card-fullheight">
            <div class="card-body p-2">
              <h5 class="box-title m-3">{{ $t('team.new') }}</h5>

              <div class="row custom-row">

                <!--  Nome  -->
                <div class="col-md-12 form-group px-2 ">
                  <label class="small grey-label mb-0">Nome</label>
                  <input name="team.nome" type="text" class="md-form-control text-left"
                    v-model="team.nome"
                    :error="submitted ? errors.first('team.nome') : ''"
                    v-validate="{ required: true }">
                  <transition name="slide" mode="">
                    <small v-if="errors.first('team.nome')"
                      class="royalc-error-field">{{ errors.first('team.nome') }}</small>
                  </transition>
                </div>

                <!-- Descrição -->
                <div class="col-md-12 form-group px-2 mb-2">
                  <label class="small grey-label mb-0">Descricao</label>
                  <custom-textarea
                    v-model="team.descricao"
                    name="team.descricao"
                    type="text"
                    rootClassName="md-form my-0"
                    inputClassName="md-form-control mt-0"
                    :rows="3"
                    v-validate="{ required: false }"
                    :error="errors.first('team.descricao')">
                  </custom-textarea>
                </div>

                <!--  Moderador  -->
                <div class="col-md-12 form-group px-2">
                  <label class="small grey-label mb-0">Moderador</label>
                  <v-select class="grey-field" name="team.moderador" label="nome" :clearable="false"
                    :searchable="true" v-model="team.moderador" :options="moderatorList"
                    @search="moderatorFetchOptions"
                    v-validate="{ required: true }">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um moderador
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum moderador encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('team.moderador')" class="royalc-error-field">{{ errors.first('team.moderador') }}</small>
                  </transition>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- Card Direita -->
        <div class="col-md-6 px-2">
          <div class="card card-fullheight">
            <div class="card-body p-2">
              <div class="row custom-row">
              <h5 class="box-title mx-2 my-3">Participantes</h5>

                <!-- Funcionários -->
                <div class="col-12 form-group px-2">
                  <label class="small grey-label mb-0">Funcionários</label>
                  <custom-advanced-multiple-select
                    id-field="id"
                    labelField="nome"
                    name="team.funcionarios"
                    class="mx-0"
                    :enableStatus="1"
                    :no-options="'Buscar Funcionário'"
                    :tableFields="employeeFields"
                    :value="team.funcionarios"
                    :options="employeeList"
                    v-bind:fetchOptions="employeeFetchOptions"
                    @onRemoved="employeeRemoved"
                    @onInput="employeeSelected"
                    @rowClicked="employeeSelected"
                    @toggleStatus="toggleEmployeeStatus"
                    v-validate="{ required: true }">
                  </custom-advanced-multiple-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('team.funcionarios')"
                      class="royalc-error-field">{{ errors.first('team.funcionarios') }}</small>
                  </transition>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="col-12 mb-5">
          <button type="button" class="btn btn-sm btn-outline-light pull-right ml-2"
            @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm pull-right"
            @click="onCancel">Cancelar</button>
          <!-- <button type="button" class="btn btn-link btn-sm mt-1 pull-right"
            @click="onShow">Console</button> -->
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import CustomAdvancedMultipleSelect from '@/components/Forms/CustomAdvancedMultipleSelect.vue'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import TeamService from '@/services/TeamService'
import EmployeeService from '@/services/EmployeeService'

export default {
  name: 'TeamForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('team.new') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: true,
      isEdit: false,
      disabled: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      //  Selected Values (Form)
      team: {
        nome: null,
        descricao: null,
        moderador: null,
        funcionarios: []
      },
      employeeList: [],
      moderatorList: [],
      showProcessingMessage: false,
      employeeFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'nome',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ]
    }
  },
  components: {
    Loading,
    vSelect,
    CustomAdvancedMultipleSelect,
    CustomTextarea
  },
  // Apply filters to local filter
  beforeMount () {
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isEdit = _this.$route.name === 'TeamEdit'
    let id = this.$route.params.id

    let filters = {
      'searchTerm': '',
      'per_page': 500,
      'includeAlan': false
    }

    EmployeeService.getFuncionarios(filters).then(res => {
      this.moderatorList = res.data.data
    })

    EmployeeService.getFuncionarios(filters).then(res => {
      let records = res.data.data
      this.employeeList = records.map((record) => {
        return {
          id: record.id,
          id_employee: record.id_employee,
          nome: record.nome,
          status: 1
        }
      })
    })

    if (id) {
      this.isLoading = true

      TeamService.get(id).then(res => {
        let data = res.data.data
        let edit = this.team

        edit.id = id
        edit.nome = data.nome
        edit.descricao = data.descricao

        if (this.isset(data.moderador)) {
          edit.moderador = {
            id: data.moderador.id,
            nome: data.moderador.id_pessoa.nome
          }
        }

        edit.funcionarios = data.funcionarios.reduce((result, x) => {
          if (!this.$util.isset(x.deleted) || !x.deleted) {
            result.push({
              id: x.funcionario.id,
              nome: x.funcionario.id_pessoa.nome,
              status: 1
            })
          }
          return result
        }, [])

        this.team = edit
        this.isLoading = false
      })
    }
  },
  methods: {
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id: object.id } }).href
    },
    /**
     * Lista de opções de moderador.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    moderatorFetchOptions (search, loading) {
      // let me = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      EmployeeService.getFuncionarios(filters).then(res => {
        let records = res.data.data
        this.moderatorList = records.map((record) => {
          return {
            id: record.id,
            id_employee: record.id_employee,
            nome: record.nome
          }
        })
        loading(false)
      })
    },
    /**
     * Lista de opções de funcionários.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search,
        'includeAlan': true
      }

      EmployeeService.getFuncionarios(filters).then(res => {
        let records = res.data.data
        this.employeeList = records.map((record) => {
          return {
            id: record.id,
            id_employee: record.id_employee,
            nome: record.nome,
            protagonista: 1,
            status: 1
          }
        })
        loading(false)
      })
    },
    employeeRemoved (e) {
    },
    employeeSelected (e) {
    },
    toggleEmployeeStatus (val) {
      let employee = {
        id: val.id,
        id_table_origin: val.id_table_origin,
        customer: val.cliente_id
      }

      if (val.status === 1) {
        if (!this.isIncluded(employee)) {
          this.task.contacts.push(employee)
        }
      } else {
        if (this.isIncluded(employee)) {
          let index = this.team.destinos.findIndex(function (element) {
            return element.id === employee.id
          })
          // remove element from selected options
          this.team.destinos.splice(index, 1)
        }
      }
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'TeamIndex' })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          if (this.isEdit) {
            // TeamService.put(this.team.id, this.team).then(response => {
            //   _this.$router.push({ name: 'TeamIndex' })
            // }).catch(err => {
            //   if (err.code === 400) {
            //     _this.displayErrors(err.errors)
            //   }
            //   _this.isLoading = false
            // })
          } else {
            TeamService.post(this.team).then(response => {
              _this.$router.push({ name: 'TeamIndex' })
              _this.isLoading = false
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          }
        }
      })
    },
    onShow () {
    },
    isset (variable) {
      return variable !== 'undefined' && variable != null && variable !== ''
    }
  },
  computed: {
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
